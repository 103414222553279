import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "../reducers/index";

// Default middleware of the redux toolkit is thunk,
// immutableStateInvariant (dev only) and serializableStateInvariant (dev only)
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
