import React from "react";

import { Box, CircularProgress } from "@material-ui/core";

export default function CenteredCircularProgress(props) {
  const size = props.size ? props.size : 56;
  const boxHeight = props.boxHeight ? props.boxHeight : 500;
  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        height={boxHeight}
        justifyContent="center"
      >
        <CircularProgress size={size} />
      </Box>
    </Box>
  );
}
