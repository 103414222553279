import { createAction } from "@reduxjs/toolkit";
import { cloneDeep, keys, entries } from "lodash";

import { getEmptyProject, compareParameters } from "./../utils/projectUtils";

// http://localhost:5000 or https://api.wasserbedarfsprognosen.de
export const API_BASE_URL = "https://api.wbp.247wasser.de";

export const trafficLightPalette = [
  "#4db6ac", // green
  "#ffd54f", // orange
  "#e57373", // red
];

export const visualizationPalette = [
  "#4c72b0",
  "#dd8452",
  "#55a868",
  "#c44e52",
  "#8172b3",
  "#937860",
  "#da8bc3",
  "#8c8c8c",
  "#ccb974",
  "#64b5cd",
];

// General state types, helpful to treat reducers as finite state machines as officially recommended
export const PROJECT_REDUCER_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

// Types for data model
export const PARAM_STATUS = {
  OPEN: "open",
  COMPLETED: "completed",
  ERROR: "error",
};

export const INP_TYPE = {
  TABLE: "Tabelle",
  SINGLE_VALUE: "Einzelwert",
  EXTERNAL: "Externe Daten",
  TEXT: "Text",
};

// Errors that may be present when checking the user input
export const DATA_ERRORS = {
  MUST_COMPLETE: "Eingaben notwendig",
  EMPTY_TABLE: "Leere Tabelle",
  ILLEGAL_TABLE_VALUE: "Tabellenwert nicht lesbar",
  ILLEGAL_PERCENTAGE_VALUE: "Falsche Prozentangabe",
  ILLEGAL_NUMBER: "Gültige, positive Zahl erforderlich",
};

// Units that need to be positive numbers in plausibility checks
export const positiveUnits = [
  "m³",
  "1000m³",
  "Anzahl Personen",
  "Zahl",
  "absolute zahl",
  "Tausend",
  "Millionen",
  "€/m³",
  "€",
  "T€",
  "mm", // TODO: It is unclear if unit mm is going to stay. Same for C° which is not covered yet
];

/* export const  */

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

/*
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
 */

// Account Fetching
export const fetchAccountRequest = createAction("account/fetch-request");
export const fetchAccountSuccess = createAction(
  "account/fetch-success",
  function prepareMetaData(payload) {
    const ID = payload.meta["selectedProjID"];
    const project = ID ? payload["meta"]["projects"][ID] : getEmptyProject();

    // The meta object should only keep meta info for all objects
    if (ID) {
      const projectLight = cloneDeep(project);
      keys(projectLight["zones"]).forEach(
        (zoneID) => (projectLight["zones"][zoneID]["sections"] = {})
      );
      payload["meta"]["projects"][ID] = projectLight;
    }

    return { payload: { meta: payload.meta, project } };
  }
);
export const fetchAccountFailure = createAction("account/fetch-failure");
// Delete all data in the browser on logout / loss of authentication
export const resetReducer = createAction("reset-reducer");
// Project Fetching
export const fetchProjectRequest = createAction("project/fetch-request");
export const fetchProjectSuccess = createAction("project/fetch-success");
export const fetchProjectFailure = createAction("project/fetch-failure");
export const createProjectRequest = createAction("project/create-request");
export const createProjectSuccess = createAction("project/create-success");
export const createProjectFailure = createAction("project/create-failure");
export const updateProjectRequest = createAction("project/update-request");
export const updateProjectSuccess = createAction("project/update-success");
export const updateProjectFailure = createAction("project/update-failure");
export const deleteProjectRequest = createAction("project/delete-request");
export const deleteProjectSuccess = createAction("project/delete-success");
export const deleteProjectFailure = createAction("project/delete-failure");

export const switchProjectRequest = createAction("project/switch-request");
export const switchProjectSuccess = createAction("project/switch-success");
export const switchProjectFailure = createAction("project/switch-failure");

// Selecting a section to work on and apply the changes to the project
export const fetchSectionRequest = createAction(
  "project/zone/section/fetch-request"
);
export const fetchSectionSuccess = createAction(
  "project/zone/section/fetch-success",
  function prepareSectionData(payload) {
    const { sectionData, zoneName, zoneID, sectionName } = payload;
    const sortedParams = Object.fromEntries(
      entries(sectionData.parameters).sort((a, b) =>
        compareParameters(a[1], b[1])
      )
    );
    const orderedSectionData = {
      ...sectionData,
      parameters: sortedParams,
    };

    return {
      payload: {
        sectionData: orderedSectionData,
        zoneName,
        zoneID,
        sectionName,
      },
    };
  }
);
export const fetchSectionFailure = createAction(
  "project/zone/section/fetch-failure"
);

export const updateSelectedSectionProperty = createAction(
  "project/selection/update-property"
);

export const createZoneRequest = createAction("project/zone/create-request");
export const createZoneSuccess = createAction("project/zone/create-success");
export const createZoneFailure = createAction("project/zone/create-failure");
export const updateZoneRequest = createAction("project/zone/update-request");
export const updateZoneSuccess = createAction("project/zone/update-success");
export const updateZoneFailure = createAction("project/zone/update-failure");
export const deleteZoneRequest = createAction("project/zone/delete-request");
export const deleteZoneSuccess = createAction("project/zone/delete-success");
export const deleteZoneFailure = createAction("project/zone/delete-failure");

// Actions for applying the changes and updating the project (both in redux and API server)
export const completeSectionReq = createAction(
  "project/zone/section/update-request"
);
export const completeSectionSucc = createAction(
  "project/zone/section/update-success"
);
export const completeSectionFail = createAction(
  "project/zone/section/update-failure"
);

// Actions for the currently selected parameter
export const selectParam = createAction("project/zone/section/param/select");

export const unselectParam = createAction(
  "project/zone/section/param/unselect"
);

export const completeParamReq = createAction(
  "project/zone/section/param/update-request"
);
export const completeParamSucc = createAction(
  "project/zone/section/param/update-success"
);
export const completeParamFail = createAction(
  "project/zone/section/param/update-failure"
);

export const resetParamReq = createAction(
  "project/zone/section/param/reset-request"
);
export const resetParamSucc = createAction(
  "project/zone/section/param/reset-success"
);
export const resetParamFail = createAction(
  "project/zone/section/param/reset-failure"
);

// Potential TODO: Choose different naming convention if it enhances readibility, e.g. url-style
// see https://redux.js.org/style-guide/style-guide#model-actions-as-events-not-setters
// and https://redux.js.org/style-guide/style-guide#write-meaningful-action-names

export const generateForecastRequest = createAction(
  "project/zone/forecast-request"
);
export const generateForecastSuccess = createAction(
  "project/zone/forecast-success"
);
export const generateForecastFailure = createAction(
  "project/zone/forecast-failure"
);

// Actions to control sidebar
export const setOpenRoutes = createAction("set-open-routes");
export const toggleOpenRoutes = createAction("toggle-open-routes");

// ##############
export const BUNDESLAENDER = {
  BW: "Baden Württemberg",
  BY: "Bayern",
  BE: "Berlin",
  BB: "Brandenburg",
  HB: "Bremen",
  HH: "Hamburg",
  HE: "Hessen",
  MV: "Mecklenburg-Vorpommern",
  NI: "Niedersachsen",
  NW: "Nordrhein Westfalen",
  RP: "Rheinland-Pfalz",
  SL: "Saarland",
  SN: "Sachsen",
  ST: "Sachsen-Anhalt",
  SH: "Schleswig-Holstein",
  TH: "Thüringen",
};

export const ZEITHORIZONT = {
  1: "1 Jahr",
  2: "2 Jahre",
  3: "3 Jahre",
  4: "4 Jahre",
  5: "5 Jahre",
  6: "6 Jahre",
  7: "7 Jahre",
  8: "8 Jahre",
  9: "9 Jahre",
  10: "10 Jahre",
  11: "11 Jahre",
  12: "12 Jahre",
  13: "13 Jahre",
  14: "14 Jahre",
  15: "15 Jahre",
  16: "16 Jahre",
  17: "17 Jahre",
  18: "18 Jahre",
  19: "19 Jahre",
  20: "20 Jahre",
  21: "21 Jahre",
  23: "23 Jahre",
  24: "24 Jahre",
  25: "25 Jahre",
  26: "26 Jahre",
  27: "27 Jahre",
  28: "28 Jahre",
  29: "29 Jahre",
  30: "30 Jahre",
};

export const SEC_NAMES = [
  "Wasserverbrauch",
  "Privathaushalte",
  "Landwirtschaft",
  "Industrie",
  "Klima",
  "Sozio-ökonomische Variablen",
];
