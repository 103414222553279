import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  getDashboardLayoutRoutes,
  //getAuthLayoutRoutes,
  getPresentationLayoutRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/auth/Page404";

import { useSelector } from "react-redux";

import { getZones } from "./../utils/projectUtils";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <element.component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const projectReducer = useSelector((state) => state.projectReducer);
  const projects = projectReducer.meta ? projectReducer.meta.projects : {};
  const zones = getZones(projectReducer.project);

  return (
    <Router>
      <Switch>
        {childRoutes(
          DashboardLayout,
          getDashboardLayoutRoutes(
            projects,
            zones,
            projectReducer.meta?.selectedProjID
          )
        )}
        {/* {childRoutes(AuthLayout, getAuthLayoutRoutes())} */}
        {childRoutes(PresentationLayout, getPresentationLayoutRoutes())}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
