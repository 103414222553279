import React from "react";
import { useSelector } from "react-redux";

import styled, { withTheme } from "styled-components/macro";

import {
  Grid,
  Hidden,
  Divider,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";
// import SelectProjectButton from "../common/SelectProjectButton";

import { getSelectedProject } from "../../utils/projectUtils";
import { isEmpty } from "lodash";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function AppBarComponent(props) {
  const meta = useSelector((state) => state.projectReducer.meta);
  const project = getSelectedProject(meta);
  const projectName = isEmpty(project)
    ? "Kein Projekt ausgewählt"
    : project.name;

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={props.onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            {/* <Grid item>
              {`${projectName}`}
              <SelectProjectButton />
            </Grid> */}

            <Grid item xs />

            <Grid item>
              {`Projekt - ${projectName}`}
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
    </React.Fragment>
  );
}

export default withTheme(AppBarComponent);
