import axios from "axios";
import auth from "./../services/authService";
//import { API_BASE_URL } from "./../constants";

const axiosClient = axios.create({
  //baseURL: API_BASE_URL,
  validateStatus: (status) => status < 500,
});

// Set that for every request, we add the Keycloak bearer token to the
// authorization header. If the user is not logged in, he will be prompted
// to log in at this point. We also update / refresh the token after every request
export const initAxios = () => {
  axiosClient.interceptors.request.use((config) => {
    if (auth.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${auth.getToken()}`;
        return Promise.resolve(config);
      };
      return auth.updateToken(cb);
    }
  });
};

export default axiosClient;
