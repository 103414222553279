import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";

import { dispatchFetchAccountData } from "./redux/actions/projectActions";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";

import { useKeycloak } from "@react-keycloak/web";

import { resetReducer } from "./constants";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const theme = useSelector((state) => state.themeReducer);
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  // If authentication status changes, either load or delete the project data
  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(dispatchFetchAccountData({ accountID: keycloak.subject }));
    } else dispatch(resetReducer());
  }, [dispatch, keycloak.authenticated, keycloak.subject]);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Wasserbedarfsprognosen"
        defaultTitle="Wasserbedarfsprognosen"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
