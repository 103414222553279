import {
  PROJECT_REDUCER_STATUS,
  resetReducer,
  fetchAccountRequest,
  fetchAccountSuccess,
  fetchAccountFailure,
  fetchProjectRequest,
  fetchProjectSuccess,
  fetchProjectFailure,
  createProjectRequest,
  createProjectSuccess,
  createProjectFailure,
  updateProjectRequest,
  updateProjectSuccess,
  updateProjectFailure,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFailure,
  switchProjectRequest,
  switchProjectSuccess,
  switchProjectFailure,
  createZoneRequest,
  createZoneSuccess,
  createZoneFailure,
  updateZoneRequest,
  updateZoneSuccess,
  updateZoneFailure,
  deleteZoneRequest,
  deleteZoneSuccess,
  deleteZoneFailure,
  updateSelectedSectionProperty,
  completeSectionReq,
  completeSectionSucc,
  completeSectionFail,
  selectParam,
  unselectParam,
  completeParamReq,
  completeParamSucc,
  completeParamFail,
  resetParamReq,
  resetParamSucc,
  resetParamFail,
  fetchSectionRequest,
  fetchSectionSuccess,
  fetchSectionFailure,
  generateForecastRequest,
  generateForecastSuccess,
  generateForecastFailure,
  setOpenRoutes,
  toggleOpenRoutes,
  PARAM_STATUS,
} from "../../constants";

import { getDeepValue, isRouteActive } from "../../utils/projectUtils";

import { createReducer } from "@reduxjs/toolkit";

import { cloneDeep, keys } from "lodash";

// Reducers are officially recommended to be treaded as finite state machiens
// https://redux.js.org/style-guide/style-guide#treat-reducers-as-state-machines
// Nevertheless I (Marcel) could not find a lot of representative usage examples
// so after experiencing some downsides of that approach and weighing up the
// benefits and risks, I chose a more standard approach by just processing actions
// and considering state variables at only a few specific times

// Meaning of the different states the reducer can have:
// Idle: No actions yet, therefore no project data
// Loading: there was a fetch_request and the project is being tried to be fetched
// Success: The project could be loaded and is ready to be accessed
// Failure: The project could not be loaded and there is NO PROJECT DATA available,
//          even if there was before the request(for simplicity and predictability purposes)
const initialState = {
  status: PROJECT_REDUCER_STATUS.IDLE,
  meta: null,
  project: null,
  selection: null,
  results: null,
  error: {},
  openRoutes: {},
};

// Important: The code is written as if it mutated the state, but it does not
// due to the immer package that is used internally by createReducer
const projectReducer = createReducer(initialState, {
  [resetReducer]: (state, action) => {
    state = { ...state, ...initialState };
  },

  [setOpenRoutes]: (state, action) => {
    /* Open collapse element of sidebar that matches current url */
    const { location, routes } = action.payload;
    if (!location || !routes) return;

    const pathName = location.pathname;
    state.openRoutes = {};

    routes.forEach((route, index) => {
      const isActive = isRouteActive(route, location);
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";
      state.openRoutes[index] = isActive || isOpen || isHome;
    });
  },

  [toggleOpenRoutes]: (state, action) => {
    const { index } = action.payload;
    // Collapse all elements
    keys(state.openRoutes).forEach(
      (item) => state.openRoutes[index] || (state.openRoutes[item] = false)
    );

    // Toggle selected element
    state.openRoutes[index] = !state.openRoutes[index];
  },

  [fetchAccountRequest]: (state, action) => {
    state.status = PROJECT_REDUCER_STATUS.LOADING;
    state.error = {};
  },

  [fetchAccountSuccess]: (state, action) => {
    state.project = { ...action.payload.project };
    state.meta = { ...action.payload.meta };
    state.status = PROJECT_REDUCER_STATUS.SUCCESS;
    state.results = null;
  },

  [fetchAccountFailure]: (state, action) => {
    state.project = null;
    state.meta = null;
    state.status = PROJECT_REDUCER_STATUS.FAILURE;
    state.error = action.payload.error;
  },

  [fetchProjectRequest]: (state, action) => {
    state.status = PROJECT_REDUCER_STATUS.LOADING;
    state.error = {};
  },

  [fetchProjectSuccess]: (state, action) => {
    state.project = { ...action.payload.project };
    state.status = PROJECT_REDUCER_STATUS.SUCCESS;
    state.results = null;
  },

  [fetchProjectFailure]: (state, action) => {
    state.project = null;
    state.status = PROJECT_REDUCER_STATUS.FAILURE;
    state.error = action.payload.error;
  },

  [createProjectRequest]: (state, action) => {
    state.error = {};
  },

  [createProjectSuccess]: (state, action) => {
    //state.status = PROJECT_REDUCER_STATUS.SUCCESS;
    const { ID, projectName, details } = action.payload;
    state["meta"]["projects"][ID] = {
      name: projectName,
      ID,
      zones: {},
      details,
    };
    state.results = null;
  },

  [createProjectFailure]: (state, action) => {
    state.error = action.payload.error;
  },

  [updateProjectRequest]: (state, action) => {
    state.error = {};
  },

  [updateProjectSuccess]: (state, action) => {
    const { projectID, projectName, details } = action.payload;
    state["meta"]["projects"][projectID] = {
      ...state["meta"]["projects"][projectID],
      name: projectName,
      projectID,
      details,
    };
  },

  [updateProjectFailure]: (state, action) => {
    state.error = action.payload.error;
  },

  [deleteProjectRequest]: (state, action) => {
    state.error = {};
  },

  [deleteProjectSuccess]: (state, action) => {
    const { ID } = action.payload;
    delete state["meta"]["projects"][ID];
    if (state.meta.selectedProjID === ID) {
      state.meta.selectedProjID = null;
      state.project = null;
    }
    state.results = null;
  },

  [deleteProjectFailure]: (state, action) => {
    state.error = action.payload.error;
  },

  [switchProjectRequest]: (state, action) => {},

  [switchProjectSuccess]: (state, action) => {
    state.meta.selectedProjID = state.project.ID;
    state.results = null;
  },

  [switchProjectFailure]: (state, action) => {},

  [createZoneRequest]: (state, action) => {
    state.error = {};
  },
  [createZoneSuccess]: (state, action) => {
    const { zoneID, projectID, zoneDetails } = action.payload;
    state["meta"]["projects"][projectID]["zones"][zoneID] = {
      ...zoneDetails,
      sections: {},
    };
    if (state.meta.selectedProjID === projectID) {
      state.project.zones = {
        ...state.project.zones,
        [zoneID]: zoneDetails,
      };
    }
  },
  [createZoneFailure]: (state, action) => {
    state.error = action.payload.error;
  },

  [updateZoneRequest]: (state, action) => {
    state.error = {};
  },
  [updateZoneSuccess]: (state, action) => {
    const { projectID, zoneID, zoneDetails } = action.payload;
    state["meta"]["projects"][projectID]["zones"][zoneID] = {
      ...state["meta"]["projects"][projectID]["zones"][zoneID],
      ...zoneDetails,
      sections: {},
    };
    if (state.meta.selectedProjID === projectID) {
      state.project.zones = {
        ...state.project.zones,
        [zoneID]: {
          ...state["project"]["zones"][zoneID],
          ...zoneDetails,
        },
      };
      if (state.results) delete state.results[zoneID];
    }
  },
  [updateZoneFailure]: (state, action) => {
    state.error = action.payload.error;
  },
  [deleteZoneRequest]: (state, action) => {
    state.error = {};
  },
  [deleteZoneSuccess]: (state, action) => {
    const { projectID, zoneID } = action.payload;
    delete state["meta"]["projects"][projectID]["zones"][zoneID];
    if (state.meta.selectedProjID === projectID) {
      delete state["project"]["zones"][zoneID];
      if (state.results) delete state.results[zoneID];
    }
  },
  [deleteZoneFailure]: (state, action) => {
    state.error = action.payload.error;
  },

  // TODO: you added the status.loading field -> check if that potentially gets thrown away in some actions
  [fetchSectionRequest]: (state, action) => {
    state.selection = { status: { loading: PROJECT_REDUCER_STATUS.LOADING } };
    state.error = {};
  },

  [fetchSectionSuccess]: (state, action) => {
    const { zoneName, zoneID, sectionName, sectionData } = action.payload;

    state.selection = {
      zoneName,
      zoneID,
      sectionName,
      section: sectionData,
      status: {
        loading: PROJECT_REDUCER_STATUS.IDLE,
        save: PROJECT_REDUCER_STATUS.IDLE,
        dirtyParams: [],
        errors: {},
      },
      selectedParam: {
        data: {},
        status: {
          save: PROJECT_REDUCER_STATUS.IDLE,
          plausibErrors: {},
        },
      },
    };
    state.selection.status.loading = PROJECT_REDUCER_STATUS.SUCCESS;
  },

  [fetchSectionFailure]: (state, action) => {
    state.selection.status.loading = PROJECT_REDUCER_STATUS.FAILURE;
    state.error = action.payload.error;
  },

  // =============

  // Actions for currently selected param
  [selectParam]: (state, action) => {
    if (isStable(state)) {
      state.error = {}; // reset errors when switching to other parameter
      const { paramName } = action.payload;
      const paramData =
        state["selection"]["section"]?.["parameters"]?.[paramName];
      if (paramData) {
        state.selection.selectedParam = {
          data: cloneDeep(paramData),
          status: {
            save: PROJECT_REDUCER_STATUS.IDLE,
            errors: {},
          },
        };
      } else
        console.log(
          `WARNING: Tried to select parameter "${paramName}" that does not exist in the selected section`
        );
    }
  },

  [unselectParam]: (state, action) => {
    doUnselectParam(state);
  },

  // Note: This updates the temporary value of the current param, not the backend
  // The backend is updated in / before completeParamSucc
  // Set the property of a parameter that is referenced through the object path
  // This path contains all the keys of the object to get to reference the desired property
  // TODO: Set param to modified in this action?
  [updateSelectedSectionProperty]: (state, action) => {
    if (state.selection && isStable(state)) {
      const { newValue, objectPath } = action.payload;
      const property = getDeepValue({
        obj: state.selection,
        path: objectPath,
        getParent: true,
      });
      const paramName = objectPath.split(".")[2];
      const propName = objectPath.split(".")[objectPath.split(".").length - 1];
      property[propName] = newValue;

      // Mark this parameter as dirty in the current selection to warn when switch to other url
      if (!state.selection.status.dirtyParams.includes(paramName))
        state.selection.status.dirtyParams = [
          ...state.selection.status.dirtyParams,
          paramName,
        ];

      // delete potentially cached results of the currently selected zone
      if (state.results) {
        const zoneID = state.selection.zoneID;
        delete state.results[zoneID];
      }
    }
  },

  [completeParamReq]: (state, action) => {
    state.selection.selectedParam.status.save = PROJECT_REDUCER_STATUS.LOADING;
    state.error = {};
  },

  [completeParamSucc]: (state, action) => {
    const { zoneID, sectionName, paramName, paramData } = action.payload;

    if (
      state.selection.zoneID !== zoneID &&
      state.selection.sectionName !== sectionName
    )
      console.log("WARNING: Updating Parameter of wrong zone / section");
    state["selection"]["section"]["parameters"][paramName] = paramData;
    state.selection.selectedParam.status = {
      save: PROJECT_REDUCER_STATUS.SUCCESS,
    };
    state["project"]["zones"][zoneID]["sections"][sectionName]["status"] =
      PARAM_STATUS.OPEN;

    state.selection.status.dirtyParams =
      state.selection.status.dirtyParams.filter((pn) => pn !== paramName);
    state.selection.selectedParam.status.plausibErrors = {};
  },

  [completeParamFail]: (state, action) => {
    const { paramData } = action.payload;
    const paramName = paramData.name;
    state["selection"]["section"]["parameters"][paramName] = paramData;
    state.selection.selectedParam.status.save = PROJECT_REDUCER_STATUS.FAILURE;
    // Errors concerning plausibility checks
    state.selection.selectedParam.status.plausibErrors =
      action.payload.plausibErrors;
    // General javascript and backend errors
    state.error = action.payload.error;
  },

  [resetParamReq]: (state, action) => {
    state.selection.selectedParam.status.save = PROJECT_REDUCER_STATUS.LOADING;
    state.error = {};
  },

  [resetParamSucc]: (state, action) => {
    const { zoneID, sectionName, paramName, paramData } = action.payload;
    if (isSelectedSection(state, zoneID, sectionName)) {
      state["selection"]["section"]["parameters"][paramName] = paramData;
      state.selection.selectedParam.status = {
        save: PROJECT_REDUCER_STATUS.SUCCESS,
      };
      // Mark section as not completed
      state["project"]["zones"][zoneID]["sections"][sectionName]["status"] =
        PARAM_STATUS.OPEN;

      // Mark parameter as not dirty
      state.selection.status.dirtyParams =
        state.selection.status.dirtyParams.filter((pn) => pn !== paramName);
      state.selection.selectedParam.status.plausibErrors = {};
    }
  },

  [resetParamFail]: (state, action) => {
    state.selection.selectedParam.status.save = PROJECT_REDUCER_STATUS.FAILURE;
    state.error = action.payload.error;
  },

  [completeSectionReq]: (state, action) => {
    doUnselectParam(state);
    state.selection.status.save = PROJECT_REDUCER_STATUS.LOADING;
    state.error = {};
  },

  [completeSectionSucc]: (state, action) => {
    const { zoneID, sectionName } = action.payload;
    if (isSelectedSection(state, zoneID, sectionName)) {
      state.selection.status.errors = {};
      state.selection.status.save = PROJECT_REDUCER_STATUS.SUCCESS;
      state["project"]["zones"][zoneID]["sections"][sectionName]["status"] =
        PARAM_STATUS.COMPLETED;
      if (state["results"] && state["results"][zoneID])
        delete state["results"][zoneID];
    }
  },

  [completeSectionFail]: (state, action) => {
    const { zoneID, sectionName, errors } = action.payload;
    if (isSelectedSection(state, zoneID, sectionName)) {
      state.selection.status.errors = errors;
      state.error = action.payload.error;
      state.selection.status.save = PROJECT_REDUCER_STATUS.FAILURE;
      state["project"]["zones"][zoneID]["sections"][sectionName]["status"] =
        PARAM_STATUS.ERROR;
      if (state["results"] && state["results"][zoneID])
        delete state["results"][zoneID];
    }
  },

  // ===== Actions for generating results
  [generateForecastRequest]: (state, action) => {},

  [generateForecastSuccess]: (state, action) => {
    const { zoneID, data } = action.payload;
    const zoneResult = {
      isValid: true,
      isModelPlausible: data.isPlausible,
      timeseriesData: data.timeseriesData,
      pieData: data.pieData,
      // Potential TODO: store error info in state.error
      errorCode: null,
      errorMsg: null,
    };
    if (!state["results"]) {
      state["results"] = { [zoneID]: zoneResult };
    } else state["results"][zoneID] = zoneResult;
  },

  // Potential TODO: store error info in state.error
  [generateForecastFailure]: (state, action) => {
    const { zoneID, error: e } = action.payload;
    const zoneResult = { errorCode: e["code"], errorMsg: e["message"] };
    if (!state["results"]) {
      state["results"] = { [zoneID]: zoneResult };
    } else state["results"][zoneID] = zoneResult;
  },
});

export default projectReducer;

/**
 * Checks whether project data is available and
 * there is no pending save operation under way
 */
function isStable(state) {
  return (
    state.status === PROJECT_REDUCER_STATUS.SUCCESS &&
    state.selection?.status?.save !== PROJECT_REDUCER_STATUS.LOADING &&
    state.selection?.selectedParam?.status?.save !==
      PROJECT_REDUCER_STATUS.LOADING
  );
}

/**
 * Checks whether the current selection corresponds to the specified
 * zoneID and sectionName
 */
function isSelectedSection(state, zoneID, sectionName) {
  if (
    state.selection.zoneID === zoneID &&
    state.selection.sectionName === sectionName
  )
    return true;
  return false;
}

function doUnselectParam(state) {
  if (isStable(state)) {
    state.selection.selectedParam = {
      data: {},
      status: {
        save: PROJECT_REDUCER_STATUS.IDLE,
        errors: {},
      },
    };
  }
}
