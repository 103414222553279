import {
  INP_TYPE,
  PARAM_STATUS,
  DATA_ERRORS,
  SEC_NAMES,
  positiveUnits,
} from "../constants";
import { isEmpty, toLower, entries, keys } from "lodash";

// TODO: Add Unit tests

export function parseNumber(value) {
  if (typeof value === "number") return value;
  else if (typeof value === "string") {
    const re = /^\s*-?\d+((\.\d+)|(,\d+))?\s*$/;
    if (value.match(re)) return parseFloat(value.replace(",", "."));
    else return null;
  }
}

export function representsNumber({
  value,
  allowNull = false,
  onlyPositive = false,
}) {
  const parsedValue = parseNumber(value);
  if (
    (allowNull && (value == null || value === "")) ||
    (!onlyPositive && parsedValue) ||
    (onlyPositive && parsedValue && parsedValue >= 0) ||
    parsedValue === 0
  )
    return true;
  else return false;
}

export function isPercentage(value, allowNull = false) {
  const number = parseNumber(value);
  return (
    (allowNull && (value == null || value === "")) ||
    ((number || number === 0) && 0 <= number && number <= 100)
  );
}

/**
 * Checks if a given value represents a date as used in the data grid(!)
 */
export function representsDate(value) {
  // Note: Considers currently only years, i.e. numbers
  return representsNumber({ value });
}

export const getZones = (project) => {
  const zones = isEmpty(project)
    ? []
    : Object.values(project.zones).map((zone) => {
        return { ...zone };
      });
  return zones;
};

export const getSelectedProject = (meta) => {
  if (!meta || !meta.hasOwnProperty("projects")) return {};
  return meta.projects[meta.selectedProjID];
};

export const getEmptyProject = () => {
  return {
    name: "",
    ID: "empty-project",
    zones: {},
    details: {
      firstName: "",
      lastName: "",
      email: "",
      zeithorizont: "",
      bemerkung: "",
    },
  };
};

export const getEmptyZone = () => {
  return {
    ID: "empty-zone",
    name: "",
    coordinates: [0, 0],
    bemerkung: "",
    sections: {},
    results: {},
    creationDate: new Date().toISOString().split("T")[0],
  };
};

export const nowAsStr = () => new Date().toISOString().split("T")[0];

/**
 * Returns the nested property of the object specified with the path
 * Example: path==="a.b.c" returns obj["a"]["b"]["c"]
 * If getParent is true, the parent, i.e. obj["a"]["b"] will be returned
 */
export function getDeepValue({ obj, path, getParent = false }) {
  if (obj) {
    var keys = path.split(".");
    var l = getParent ? keys.length - 1 : keys.length;
    for (var i = 0, len = l; i < len; i++) {
      obj = obj[keys[i]];
    }
    return obj;
  }
  return null;
}

/**
 * Returns an array with descending numbers beginning at start
 * If no end is specified, the current year will be selected as end
 */
export function getArrayOfYears({ start = null, end = null, asStr = false }) {
  // Get current year if none is specified
  start = start ? start : new Date().getFullYear();
  end = end ? end : new Date().getFullYear();
  const years = [];
  for (var i = start; i <= end; i++)
    asStr ? years.push(i.toString()) : years.push(i);
  return years;
}

/**
 * Takes a parameter object as defined in a section and
 * returns the available input types as a list of strings
 */
export function getInputTypes(param) {
  return param ? Object.keys(param.input) : [];
}

/**
 * Takes a parameter object as defined in a section and
 * returns the available units for table input as a list of strings
 */
export function getTableUnitsAvailable(param) {
  if (!getInputTypes(param).includes(INP_TYPE.TABLE)) return [];
  return param["input"][INP_TYPE.TABLE]["unitsAvailable"];
}

/**
 * Takes a parameter object as defined in a section and
 * returns the temporal scope "isFuture" of the table
 */
export function getTableIsFuture(param) {
  if (!getInputTypes(param).includes(INP_TYPE.TABLE)) return [];
  return param["input"][INP_TYPE.TABLE]["isFuture"];
}

/**
 * Takes a parameter object as defined in a section and
 * returns the available units for single value input as a list of strings
 */
export function getSingleUnitsAvailable(param) {
  if (!getInputTypes(param).includes(INP_TYPE.SINGLE_VALUE)) return [];
  return param["input"][INP_TYPE.SINGLE_VALUE]["unitsAvailable"];
}

/**
 * Checks whether the parameter in the selection has input type table
 */
export function isTableInput(selection, paramName) {
  if (selection && paramName) {
    const inputType = getDeepValue({
      obj: selection,
      path: `section.parameters.${paramName}.selectedInpType`,
    });
    return inputType === INP_TYPE.TABLE;
  }
  return false;
}

/**
 * Returns the rows (table data) for the paramName
 * If build is true, the rows will be build new considering the start and end date
 * keeping the entries that fit into the date range and creating new ones for the others
 */
export function getTableRows({ selection, paramName, build = false }) {
  const rows = paramName
    ? getDeepValue({
        obj: selection,
        path: `section.parameters.${paramName}.input.${INP_TYPE.TABLE}.rows`,
      })
    : [];

  if (!build) return rows ?? [];

  const startYear = getDeepValue({
    obj: selection,
    path: `section.parameters.${paramName}.input.${INP_TYPE.TABLE}.startYear`,
  });
  const endYear = getDeepValue({
    obj: selection,
    path: `section.parameters.${paramName}.input.${INP_TYPE.TABLE}.endYear`,
  });

  if (
    !representsDate(startYear) ||
    !representsDate(endYear) ||
    startYear > endYear
  )
    return [];

  const rowsStartDate = rows.length > 0 ? rows[0].date : null;
  const rowsEndDate = rows.length > 0 ? rows[rows.length - 1].date : null;

  const dates = getArrayOfYears({
    start: startYear,
    end: endYear,
    asStr: true,
  });

  // If there is already an entry for that date, use that value
  // Otherwise make an entry with no value yet
  const newRows = [];
  dates.forEach((date) => {
    if (rowsStartDate <= date && date <= rowsEndDate)
      newRows.push(rows.filter((entry) => entry.date === date)[0]);
    else newRows.push({ date: date, value: null });
  });
  return newRows;
}

export function getPredYear(projectDetails) {
  const curYear = new Date().getFullYear();
  const zeithorizont = projectDetails["zeithorizont"];
  const offsetInYears = parseInt(zeithorizont.split(" ")[0]);
  return curYear + offsetInYears;
}

/**
 * Checks the integrity of the sectionData and returns an object
 * with one error object per error type
 */
export function checkErrors(sectionData) {
  const errors = {};
  if (!sectionData) {
    errors["no data"] = true;
    return errors;
  }

  for (var paramName in sectionData.parameters) {
    const param = sectionData["parameters"][paramName];

    if (!param["optional"] && param["status"] !== PARAM_STATUS.COMPLETED) {
      createArrayIfNotExists(errors, DATA_ERRORS.MUST_COMPLETE);
      errors[DATA_ERRORS.MUST_COMPLETE].push(paramName);
    }

    // Note: plausibCheck sets the entries of the errors variable
    plausibCheckParam({ param, errors });
  }
  return errors;
}

/**
 * Checks for errors for the specified parameter.
 * Returns a dictionary with the error type as keys.
 */
export function plausibCheckParam(param, errors = {}) {
  if (param["selectedInpType"] === INP_TYPE.TABLE) {
    const rows = param["input"][INP_TYPE.TABLE]["rows"];

    // Set error if table is empty, but not optional
    /* if (!param["optional"] && (!rows || rows.length === 0)) {
      createArrayIfNotExists(errors, DATA_ERRORS.EMPTY_TABLE);
      errors[DATA_ERRORS.EMPTY_TABLE].push(param.name);
    } */

    rows.forEach((row) => {
      if (!representsNumber({ value: row["value"], allowNull: true })) {
        createArrayIfNotExists(errors, DATA_ERRORS.ILLEGAL_TABLE_VALUE);
        pushIfNotExists(errors[DATA_ERRORS.ILLEGAL_TABLE_VALUE], param.name);
      }
    });
  }

  if (param["selectedInpType"] === INP_TYPE.SINGLE_VALUE) {
    const unit = param["input"][INP_TYPE.SINGLE_VALUE]["unit"];
    const value = param["input"][INP_TYPE.SINGLE_VALUE]["value"];
    if (unit === "%" && !isPercentage(value)) {
      createArrayIfNotExists(errors, DATA_ERRORS.ILLEGAL_PERCENTAGE_VALUE);
      errors[DATA_ERRORS.ILLEGAL_PERCENTAGE_VALUE].push(param.name);
    } else if (
      positiveUnits.includes(unit) &&
      !representsNumber({ value, onlyPositive: true })
    ) {
      createArrayIfNotExists(errors, DATA_ERRORS.ILLEGAL_NUMBER);
      errors[DATA_ERRORS.ILLEGAL_NUMBER].push(param.name);
    }
  }

  return errors;
}

/**
 * Utility function for readability purposes
 */
function createArrayIfNotExists(obj, key) {
  obj[key] = obj[key] ?? [];
  return obj;
}

/**
 * Utility function for readability purposes
 */
function pushIfNotExists(arr, value) {
  if (arr.indexOf(value) === -1) arr.push(value);
}

/**
 * Returns a color based on the completion status
 */
export function getStatusColor({ status, palette, isSidebar }) {
  return status === PARAM_STATUS.COMPLETED
    ? palette["signaling"]["done"]
    : status === PARAM_STATUS.OPEN
    ? palette["signaling"]["edit"]
    : palette["signaling"]["error"];
}

/**
 * Returns the next section that is still to be completed.
 * Chooses the section that follows the specified one chronologically.
 * If no zone and section are given, starts at the first one (i.e. after the last one)
 * Returns an empty object if no unfinished section exists.
 */
export function getNextSection(project, zoneID = null, sectionName = null) {
  if (!project) return;

  const zoneBySection = {};

  // Create a list of zones for every section type (for correct ordering)
  // We use SEC_NAMES, as it guarantees a fixed ordering
  Object.entries(project.zones).forEach(([ZoneKey, zone]) =>
    SEC_NAMES.forEach((sectionName) => {
      createArrayIfNotExists(zoneBySection, sectionName);
      zoneBySection[sectionName].push({
        zoneID: zone.ID,
        sectionName: sectionName,
        status: zone["sections"][sectionName]["status"],
      });
    })
  );

  // Find position/idx of current section and find the chronologically next one
  // If no zone or section is given, we start from the beginning (i.e. after the last one)
  var oldIdx;
  const allSections = [].concat(...Object.values(zoneBySection));
  if (zoneID && sectionName)
    allSections.forEach((section, i) => {
      if (section.sectionName === sectionName && section.zoneID === zoneID) {
        oldIdx = i;
      }
    });
  else oldIdx = allSections.length - 1;

  // Check for the next section that is not yet comleted
  for (var idx = oldIdx + 1; idx < allSections.length; idx++)
    if (allSections[idx].status !== PARAM_STATUS.COMPLETED) {
      return {
        newSectionName: allSections[idx].sectionName,
        newZoneID: allSections[idx].zoneID,
        status: allSections[idx].status,
      };
    }
  for (idx = 0; idx <= oldIdx; idx++)
    if (allSections[idx].status !== PARAM_STATUS.COMPLETED) {
      return {
        newSectionName: allSections[idx].sectionName,
        newZoneID: allSections[idx].zoneID,
        status: allSections[idx].status,
      };
    }
  return;
}

// Determines the ordering of two parameters
export function compareParameters(param1, param2) {
  if (param1.optional && !param2.optional) return 1;
  else if (!param1.optional && param2.optional) return -1;
  else if (param1.name === "Bemerkung") return 1;
  else if (param2.name === "Bemerkung") return -1;
  else return toLower(param1.name) > toLower(param2.name);
}

// This function should no longer be used
export const initSections = () => {};

// Functions to check if error for current component applies and create error description
export const isParamPlausib = (param) => isEmpty(param.status.plausibErrors);
export const isError = (e, actions) => keys(actions).includes(e.fromAction);
export const getErrorMsg = (e, actions) => {
  var actionDescription;
  for (const [code, description] of entries(actions))
    if (e.fromAction === code) actionDescription = description;

  if (actionDescription)
    return `Beim ${actionDescription} ist ein Fehler aufgetreten.`;
  return "Unerwarteter Fehler.";
};

/**
 * Checks whether the current location / url matches the specified route.
 * @param {Object} route: The route to check against, contains a regexpr like the following:
 *                        ^\/ergebnisse\/zonenspezifisch\/.+
 *                        for path "/ergebnisse/zonenspezifisch/:zoneID"
 * @param {Object} location: Contains the current page's url as prop, e.g. "/ergebnisse/zonenspezifisch/1111-2222-3333"
 * @returns Boolean, representing whether the location matches the specified path
 */
export const isRouteActive = (route, location) =>
  new RegExp(route.regexpr).test(location.pathname);
