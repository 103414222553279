import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import store from "./redux/store/index";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { getKeycloak } from "./services/authService";
import { initAxios } from "./utils/axios";

import CenteredCircularProgress from "./components/info/CenteredCircularProgress";

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={getKeycloak()}
    initOptions={{ onLoad: "check-sso" }}
    LoadingComponent={<CenteredCircularProgress />}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

initAxios();
