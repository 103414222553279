import {
  fetchAccountRequest,
  fetchAccountSuccess,
  fetchAccountFailure,
  fetchProjectRequest,
  fetchProjectSuccess,
  fetchProjectFailure,
  createProjectRequest,
  createProjectSuccess,
  createProjectFailure,
  updateProjectRequest,
  updateProjectSuccess,
  updateProjectFailure,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFailure,
  switchProjectRequest,
  switchProjectSuccess,
  switchProjectFailure,
  createZoneRequest,
  createZoneSuccess,
  createZoneFailure,
  updateZoneRequest,
  updateZoneSuccess,
  updateZoneFailure,
  deleteZoneRequest,
  deleteZoneSuccess,
  deleteZoneFailure,
  fetchSectionRequest,
  fetchSectionSuccess,
  fetchSectionFailure,
  completeSectionReq,
  completeSectionSucc,
  completeSectionFail,
  completeParamReq,
  completeParamSucc,
  completeParamFail,
  resetParamReq,
  resetParamSucc,
  resetParamFail,
  generateForecastRequest,
  generateForecastSuccess,
  generateForecastFailure,
  PARAM_STATUS,
} from "../../constants";

import { checkErrors, plausibCheckParam } from "./../../utils/projectUtils";

import {
  fetchAccountData,
  fetchProject,
  fetchSection,
  setSectionCompleted,
  applyParamChangesServer,
  resetParamServer,
  updateProject,
  createProject,
  deleteProject,
  updateZone,
  createZone,
  deleteZone,
} from "../../services/storageServices";

import { generateZoneForecast } from "../../services/resultServices";

function get_error(error, fromAction) {
  // Note: errorCode and errorMsg are fields that come from responses from the backend
  // error.message is a javascript error field and fromAction is info added by the frontend
  const code = error.errorCode || fromAction;
  const message = error.errorMsg || error.message;

  const e = {
    error: {
      code,
      message,
      fromAction,
    },
  };
  return e;
}

export function dispatchFetchAccountData({ accountID }) {
  return async (dispatch) => {
    dispatch(fetchAccountRequest());

    return fetchAccountData(accountID)
      .then((response) => {
        dispatch(fetchAccountSuccess({ meta: response.meta }));
      })
      .catch((e) => {
        dispatch(fetchAccountFailure(get_error(e, "fetch-account")));
      });
  };
}

/**
 *
 * @param {*} ID The project ID to fetch.
 * @param {*} setSelected Whether to set this project as the selected project (both client-side and server-side).
 *                        If true, switchProjectSuccess or switchProjectFailure will be dispatched.
 * @returns
 */
export function dispatchFetchProject({ ID, setSelected }) {
  return async (dispatch) => {
    dispatch(fetchProjectRequest());

    return fetchProject({ ID, setSelected })
      .then((response) => {
        dispatch(fetchProjectSuccess({ project: response.project }));
        if (setSelected) dispatch(switchProjectSuccess());
      })
      .catch((e) => {
        dispatch(fetchProjectFailure(get_error(e, "fetch-project")));
        if (setSelected) dispatch(switchProjectFailure());
      });
  };
}

export function dispatchUpdateProject({ projectID, projectName, details }) {
  return async (dispatch) => {
    dispatch(updateProjectRequest());

    return new Promise((resolve, reject) =>
      updateProject({ projectID, projectName, details })
        .then((response) => {
          dispatch(updateProjectSuccess({ projectID, projectName, details }));
          return resolve();
        })
        .catch((e) => {
          dispatch(updateProjectFailure(get_error(e, "update-project")));
          return reject(e);
        })
    );
  };
}

/**
 *  Sends a request to create a new project.
 *  Receives the new project ID as answer.
 */
export function dispatchCreateProject({ projectName, details }) {
  return async (dispatch) => {
    dispatch(createProjectRequest());

    // Note: promise is necessary to return ID with resolve
    return new Promise((resolve, reject) => {
      createProject({ projectName, details })
        .then((response) => {
          dispatch(
            createProjectSuccess({ ID: response.ID, projectName, details })
          );
          return resolve({ ID: response.ID });
        })
        .catch((e) => {
          dispatch(createProjectFailure(get_error(e, "create-project")));
          return reject(e);
        });
    });
  };
}

export function dispatchDeleteProject({ ID }) {
  return async (dispatch) => {
    dispatch(deleteProjectRequest());

    return new Promise((resolve, reject) =>
      deleteProject({ ID })
        .then((response) => {
          dispatch(deleteProjectSuccess({ ID }));
          return resolve();
        })
        .catch((e) => {
          dispatch(deleteProjectFailure(get_error(e, "delete-project")));
          return reject(e);
        })
    );
  };
}

/**
 * Switches the project by fetching the data from the backend and setting the selected project ID.
 * Note: return the dispatchFetchProject to use .then() on the function's return value
 * @param {*} ID The project ID to fetch.
 * @returns
 */
export function switchProject({ ID }) {
  return async (dispatch) => {
    dispatch(switchProjectRequest());
    return dispatch(dispatchFetchProject({ ID, setSelected: true }));
  };
}

export function dispatchFetchSection({ projectID, zoneID, sectionName }) {
  return async (dispatch) => {
    dispatch(fetchSectionRequest());

    return fetchSection({ projectID, zoneID, sectionName })
      .then((response) => {
        dispatch(
          fetchSectionSuccess({
            sectionData: response.section,
            zoneName: response.zoneName,
            zoneID,
            sectionName,
          })
        );
      })
      .catch((e) =>
        dispatch(fetchSectionFailure(get_error(e, "fetch-section")))
      );
  };
}

export function dispatchUpdateZone({ projectID, zoneID, zoneDetails }) {
  return async (dispatch) => {
    dispatch(updateZoneRequest());

    return new Promise((resolve, reject) =>
      updateZone({ projectID, zoneID, zoneDetails })
        .then((response) => {
          dispatch(updateZoneSuccess({ projectID, zoneID, zoneDetails }));
          return resolve();
        })
        .catch((e) => {
          dispatch(updateZoneFailure(get_error(e, "update-zone")));
          return reject();
        })
    );
  };
}

/**
 *  Sends a request to create a new project.
 *  Receives the new project ID as answer,
 *  alongside the creation date and sections.
 */
export function dispatchCreateZone({ projectID, zoneDetails }) {
  return async (dispatch) => {
    dispatch(createZoneRequest());

    // Note: Promise is used to signal to ZoneForm whether creation worked
    return new Promise((resolve, reject) =>
      createZone({ projectID, zoneDetails })
        .then((response) => {
          dispatch(
            createZoneSuccess({
              zoneID: response.ID,
              projectID,
              zoneDetails: {
                ...zoneDetails,
                ID: response.ID,
                creationDate: response.creationDate,
                sections: response.sections,
              },
            })
          );
          return resolve();
        })
        .catch((e) => {
          dispatch(createZoneFailure(get_error(e, "create-zone")));
          return reject();
        })
    );
  };
}

export function dispatchDeleteZone({ projectID, zoneID }) {
  return async (dispatch) => {
    dispatch(deleteZoneRequest());

    return new Promise((resolve, reject) =>
      deleteZone({ projectID, zoneID })
        .then((response) => {
          dispatch(deleteZoneSuccess({ projectID, zoneID }));
          return resolve();
        })
        .catch((e) => {
          dispatch(deleteZoneFailure(get_error(e, "delete-zone")));
          return reject(e);
        })
    );
  };
}

/**
 * Sets the status of the current section to completed if there are no errors.
 * Updates either both the redux state and the API Server or none of them.
 */
export function completeSection({
  projectID,
  zoneID,
  sectionName,
  sectionData,
}) {
  return async (dispatch) => {
    dispatch(completeSectionReq());

    const errors = checkErrors(sectionData);
    if (Object.keys(errors).length > 0) {
      dispatch(
        completeSectionFail({
          projectID,
          zoneID,
          sectionName,
          errors,
          error: {
            code: "Fehlerhafte Angaben",
            message: "Angaben überprüfen",
            fromAction: "complete-section",
          },
        })
      );
      return;
    }

    return setSectionCompleted(projectID, zoneID, sectionName)
      .then((response) => {
        dispatch(
          completeSectionSucc({
            projectID,
            zoneID,
            sectionName,
          })
        );
      })
      .catch((e) => {
        dispatch(
          completeSectionFail({
            projectID,
            zoneID,
            sectionName,
            errors: {}, // old property
            ...get_error(e, "complete-section"),
          })
        );
      });
  };
}

/**
 * Sets the current parameter's status to completed and updates the API server
 * if there are no errors.
 */
export function applySelectedParamChanges({
  projectID,
  zoneID,
  sectionName,
  paramData,
  onSucc,
}) {
  const paramName = paramData.name;

  return async (dispatch) => {
    //console.log("Calling applySelectedParamChanges");
    dispatch(completeParamReq());

    const plausibErrors = plausibCheckParam(paramData);
    if (Object.keys(plausibErrors).length > 0) {
      dispatch(
        completeParamFail({
          error: {
            code: "Fehlerhafte Angaben",
            message: "Angaben überprüfen",
            fromAction: "complete-param",
          },
          plausibErrors,
          paramData: { ...paramData, status: PARAM_STATUS.ERROR },
        })
      );
      return;
    }

    return applyParamChangesServer({
      projectID,
      zoneID,
      sectionName,
      paramName,
      paramData: { ...paramData, status: PARAM_STATUS.COMPLETED },
    })
      .then((response) => {
        dispatch(
          completeParamSucc({
            projectID,
            zoneID,
            sectionName,
            paramName,
            paramData: { ...paramData, status: PARAM_STATUS.COMPLETED },
          })
        );
        onSucc();
      })
      .catch((e) => {
        dispatch(
          completeParamFail({
            ...get_error(e, "complete-param"),
            plausibErrors: {},
            paramData: { ...paramData, status: PARAM_STATUS.ERROR },
          })
        );
      });
  };
}

/**
 * Resets the specified parameter and updates the API server
 */
export function dispatchResetParam({
  projectID,
  zoneID,
  sectionName,
  paramName,
}) {
  return async (dispatch) => {
    dispatch(resetParamReq());

    return resetParamServer({
      projectID,
      zoneID,
      sectionName,
      paramName,
    })
      .then((response) => {
        dispatch(
          resetParamSucc({
            projectID,
            zoneID,
            sectionName,
            paramName,
            paramData: response.paramData,
          })
        );
      })
      .catch((e) => {
        dispatch(resetParamFail(get_error(e, "reset-param")));
      });
  };
}

export function dispatchGenerateZoneForecast({ projectID, zoneID }) {
  return async (dispatch) => {
    dispatch(generateForecastRequest());

    return new Promise((resolve, reject) =>
      generateZoneForecast(projectID, zoneID)
        .then((response) => {
          dispatch(
            generateForecastSuccess({ projectID, zoneID, data: response })
          );
          return resolve();
        })
        .catch((e) => {
          dispatch(
            generateForecastFailure({ ...get_error(e, "delete-zone"), zoneID })
          );
        })
    );
  };
}
