import axios from "../utils/axios";
import { API_BASE_URL } from "../constants";

/* 
Important: The data / parameters for get and put requests are passed differently
Please refer to the axios docs to learn more: https://axios-http.com/docs/api_intro
*/

export function fetchAccountData({ accountID }) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/api/user/read`, { params: { accountID } })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

/**
 *
 * @param {*} ID The ID of the project to fetch
 * @param {*} setSelected Whether the project should be set to the selected project at the backend
 * @returns
 */
export function fetchProject({ ID, setSelected }) {
  return new Promise((resolve, reject) => {
    const data = setSelected ? { ID, setSelected } : { params: { ID } };
    const rest_req = setSelected ? axios.put : axios.get;
    rest_req(`${API_BASE_URL}/api/project/read`, data)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function createProject({ projectName, details }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/create`, {
        projectName,
        details,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function updateProject({ projectID, projectName, details }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/update`, {
        projectID,
        projectName,
        details,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function deleteProject({ ID }) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/api/project/delete`, { params: { ID } })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function createZone({ projectID, zoneDetails }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/zone/create`, {
        projectID,
        zoneDetails,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function updateZone({ projectID, zoneID, zoneDetails }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/zone/update`, {
        projectID,
        zoneID,
        zoneDetails,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function deleteZone({ projectID, zoneID }) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/api/project/zone/delete`, {
        params: { projectID, zoneID },
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

/**
 * Fetches data for the specified section from the API server.
 */
export function fetchSection({ projectID, zoneID, sectionName }) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/api/project/zone/section/read`, {
        params: { projectID, zoneID, sectionName },
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

// TODO: On backend server - do not only set sections to completed but also to error?
export function setSectionCompleted(projectID, zoneID, sectionName) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/update/section`, {
        projectID,
        zoneID,
        sectionName,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

/**
 * Updates the specified parameter on the API server.
 */
export function applyParamChangesServer({
  projectID,
  zoneID,
  sectionName,
  paramName,
  paramData,
}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/update/param`, {
        projectID,
        zoneID,
        sectionName,
        paramName,
        paramData,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

/**
 * Resets the specified parameter on the API server.
 */
export function resetParamServer({
  projectID,
  zoneID,
  sectionName,
  paramName,
}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/api/project/reset/param`, {
        projectID,
        zoneID,
        sectionName,
        paramName,
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
