import React from "react";

// async returns async components that are replaced by loading indicators if necessary
import async from "../components/common/Async";

import { Briefcase, Monitor } from "react-feather"; // { Users }

import {
  LocalDrink,
  People,
  WbSunny,
  Assessment,
  Apartment,
  Restaurant,
  ShowChart,
  Home,
} from "@material-ui/icons";

// Guards
const AuthGuard = async(() => import("../components/common/AuthGuard"));

// Layout to select, modify and delete a Layout
const ProjectOverviewLayout = async(() =>
  import("../components/project/ProjectOverviewLayout")
);

const ZoneOverviewLayout = async(() =>
  import("../components/zone/ZoneOverviewLayout")
);

const HomeLayout = async(() => import("../components/home/HomeLayout"));

// Input components
const InputLayout = async(() => import("../components/input/InputLayout"));

// Result components
const ResultLayout = async(() => import("../components/result/ResultLayout"));

/* 
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
 */

// Landing
const Landing = async(() => import("../pages/landing"));

// Imprint
const Imprint = async(() => import("../pages/landing/Imprint"));

// Privacy Policy
const PrivacyPolicy = async(() => import("../pages/landing/PrivacyPolicy"));

const homeRoute = {
  id: "Home",
  path: "/allgemein/home",
  icon: <Home />,
  header: "Allgemein",
  component: HomeLayout,
  children: null,
  guard: AuthGuard,
};

const getProjectRoutes = (projects, projID, isSidebarLink) => {
  //console.log(projects);
  const generalRoute = {
    path: `/allgemein/projekte/:projectID`,
    name: "Projektdefinition",
    component: ProjectOverviewLayout,
    guard: AuthGuard,
  };

  const sidebarRoute = (project) => {
    return {
      path: `/allgemein/projekte/${project.ID}`,
      name: project.name,
      component: ProjectOverviewLayout,
      badge: project.ID === projID ? "✓" : null,
      guard: AuthGuard,
    };
  };

  const newProjRoute = {
    path: `/allgemein/projekte/neues-projekt`,
    name: "Projekt hinzufügen",
    component: ProjectOverviewLayout,
    badge: "+",
    guard: AuthGuard,
  };

  return {
    id: "Projekte",
    path: "/allgemein/projekte/:projectID",
    //header: "Allgemein",
    icon: <Briefcase />,
    children: isSidebarLink
      ? [
          ...Object.values(projects).map((project) => sidebarRoute(project)),
          newProjRoute,
        ]
      : [generalRoute, newProjRoute],
  };
};

const zoneRoute = {
  id: "Zone",
  path: "/allgemein/projekte/:projectID/zonen/:zoneID",
  component: ZoneOverviewLayout,
  guard: AuthGuard,
};

// use isLink === True if the actual path that is shown on the sidebar is desired
// Otherwise the path is specified with the parameter :zoneID
const inputRoutes = (
  inpStep,
  component,
  zones,
  icon,
  header,
  isSidebarLink
) => {
  const generalRoute = {
    path: `/dateneingabe/${inpStep}/:zoneID`,
    name: `${inpStep} zone parameter`,
    component: component,
    guard: AuthGuard,
  };

  // What happens here is that static paths are created in addition to the parameterized path
  // (the general route above). But the static paths can only be created once the zone data is available
  // Therefore it is possible that the compononet must render without this info available
  // Hence the generalRoute above
  const sidebarRoute = (zone) => {
    return {
      path: `/dateneingabe/${inpStep}/${zone.ID}`,
      /* path: `/dateneingabe/${inpStep}/${name.replace(" ", "-")}`, */
      name: zone.name,
      component: component,
      status: zone["sections"]?.[inpStep]?.status,
      guard: AuthGuard,
    };
  };

  return {
    id: inpStep,
    path: `/dateneingabe/${inpStep}`,
    header,
    icon,
    children: isSidebarLink
      ? zones.map((zone) => sidebarRoute(zone))
      : [generalRoute],
  };
};

const getAllInputRoutes = (zones, isSidebarLink) => {
  const routes = [];
  routes.push(
    inputRoutes(
      "Wasserverbrauch",
      InputLayout,
      zones,
      <LocalDrink />,
      "Dateneingabe",
      isSidebarLink
    )
  );
  routes.push(
    inputRoutes(
      "Privathaushalte",
      InputLayout,
      zones,
      <People />,
      null,
      isSidebarLink
    )
  );
  routes.push(
    inputRoutes(
      "Landwirtschaft",
      InputLayout,
      zones,
      <Restaurant />,
      null,
      isSidebarLink
    )
  );
  routes.push(
    inputRoutes(
      "Industrie",
      InputLayout,
      zones,
      <Apartment />,
      null,
      isSidebarLink
    )
  );
  routes.push(
    inputRoutes("Klima", InputLayout, zones, <WbSunny />, null, isSidebarLink)
  );
  routes.push(
    inputRoutes(
      "Sozio-ökonomische Variablen",
      InputLayout,
      zones,
      <Assessment />,
      null,
      isSidebarLink
    )
  );
  return routes;
};

// Possible Icons:
// import DataUsageIcon from '@material-ui/icons/DataUsage';
// import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
/* const resultRoutes = {
  id: "Ergebnisse",
  path: "/berechnungen",
  header: "Berechnungen",
  icon: <ShowChart />,
  component: ResultLayout,
}; */

const getResultRoutes = (zones, isSidebarLink) => {
  const generalRoute = {
    path: `/ergebnisse/zonenspezifisch/:zoneID`,
    name: `zone results`,
    component: ResultLayout,
    guard: AuthGuard,
  };

  const sidebarRoute = (zone) => {
    return {
      path: `/ergebnisse/zonenspezifisch/${zone.ID}`,
      name: zone.name,
      component: ResultLayout, // TODO: create the layout dynamic from the zone's data
      /* status: zone["sections"]?.[inpStep]?.status, */
      guard: AuthGuard,
    };
  };

  return {
    id: "Zonenspezifisch",
    path: "/ergebnisse/zonenspezifisch/:zoneID",
    header: "Ergebnisse",
    icon: <ShowChart />,
    children: isSidebarLink
      ? zones.map((zone) => sidebarRoute(zone))
      : [generalRoute],
  };
};

/* const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
}; */

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const imprintRoutes = {
  id: "Imprint Page",
  path: "/impressum",
  header: "Impressum",
  component: Imprint,
};

const privacyPolicyRoutes = {
  id: "Privacy Policy Page",
  path: "/datenschutz",
  header: "Datenschutz",
  component: PrivacyPolicy,
};

// Routes using the Dashboard layout
export const getDashboardLayoutRoutes = (projects, zones, projID) => [
  homeRoute,
  zoneRoute,
  getProjectRoutes(projects, projID, false),
  ...getAllInputRoutes(zones, false),
  getResultRoutes(zones, false),
];

// Routes using the Auth layout
// export const getAuthLayoutRoutes = () => [authRoutes];

// Routes using the Presentation layout
export const getPresentationLayoutRoutes = () => [
  landingRoutes,
  imprintRoutes,
  privacyPolicyRoutes,
];

// Routes visible in the sidebar
export const getSidebarRoutes = (projects, zones, projID) => {
  // If no project is selected, we want the sidebar to be cleaner
  const projDependentRoutes = projID
    ? [...getAllInputRoutes(zones, true), getResultRoutes(zones, true)]
    : [];

  return [
    homeRoute,
    getProjectRoutes(projects, projID, true),
    ...projDependentRoutes,
    /* authRoutes, */
  ];
};
