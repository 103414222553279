import axios from "../utils/axios";
import Keycloak from "keycloak-js";

const _kc = new Keycloak("/keycloak.json");

const isLoggedIn = () => Boolean(_kc.token);

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const keycloak = {
  isLoggedIn,
  doLogin,
  doLogout,
  getToken,
  updateToken,
};

export default keycloak;

export const getKeycloak = () => _kc;

/* Below is DEPRECATED and can be deleted once all template pages
that may still be using them are deleted as well */
export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        return reject(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
