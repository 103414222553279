import axios from "../utils/axios";
import { API_BASE_URL } from "../constants";

export function generateZoneForecast(projectID, zoneID) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/api/project/zone/forecast`, {
        params: { projectID, zoneID },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
